import Footer from "../include/Footer";
import Header from "../include/Header";

const Weblayout = ({ children, pageName}) => {

    return (
        <>
    <Header/>
    {children}
    <Footer/>
    </>
    );
}

export default Weblayout