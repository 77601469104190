import { Link } from "react-router-dom";
import { useState } from "react";
import PopupComponent from "../components/PopupComponent";

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <header className="transparent-header">
      <PopupComponent isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className="tg-header__top">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-8">
              <ul className="tg-header__top-info left-side list-wrap">
                <li>
                  <i className="flaticon-phone-call"></i>
                  <Link to="tel:9971713967">+91-9971713967</Link>
                </li>
                <li>
                  <i className="flaticon-pin"></i>C-7/82, Sector-8, Rohini, New
                  Delhi-110085
                </li>
              </ul>
            </div>
            <div className="col-lg-4">
              <ul className="tg-header__top-right list-wrap">
                <li>
                  <i className="flaticon-envelope"></i>
                  <Link to="mailto:care@abhhyamsecure.com">
                    care@abhhyamsecure.com
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id="sticky-header" className="tg-header__area">
        <div className="container custom-container">
          <div className="row">
            <div className="col-12">
              <div className="tgmenu__wrap">
                <nav className="tgmenu__nav">
                  <div className="logo">
                    <Link to="/">
                      <img src="assets/img/logo/logo.png" alt="Logo" />
                    </Link>
                  </div>
                  <div className="tgmenu__navbar-wrap tgmenu__main-menu d-none d-lg-flex">
                    <ul className="navigation">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li className="menu-item-has-children">
                        <Link to="#">About Company</Link>
                        <ul className="sub-menu">
                          <li>
                            <Link to="/about">About ASL</Link>
                          </li>
                          <li>
                            <Link to="/founder">Founder Team</Link>
                          </li>
                          <li>
                            <Link to="/certification">Certification</Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-has-children">
                        <Link to="#">Marketing Plan</Link>
                        <ul className="sub-menu">
                          <li>
                            <Link to="/initiative">Initiative</Link>
                          </li>
                          <li>
                            <Link to="/direct_marketing_plan">
                              Marketing Plan
                            </Link>
                          </li>
                          <li>
                            <Link to="/advantages">Advantages</Link>
                          </li>
                          <li>
                            <Link to="/incentives">Incentives</Link>
                          </li>
                          <li>
                            <Link to="/members">Members</Link>
                          </li>
                          <li>
                            <Link to="/general">General</Link>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <Link to="/downloads">Download</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="tgmenu__action d-none d-md-block">
                    <ul className="list-wrap">
                      <li className="offCanvas-menu">
                        <Link
                          to="#"
                          className="menu-tigger"
                          onClick={() => setIsOpen(true)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 18 18"
                            fill="none">
                            <path
                              d="M0 2C0 0.895431 0.895431 0 2 0C3.10457 0 4 0.895431 4 2C4 3.10457 3.10457 4 2 4C0.895431 4 0 3.10457 0 2Z"
                              fill="currentcolor"
                            />
                            <path
                              d="M0 9C0 7.89543 0.895431 7 2 7C3.10457 7 4 7.89543 4 9C4 10.1046 3.10457 11 2 11C0.895431 11 0 10.1046 0 9Z"
                              fill="currentcolor"
                            />
                            <path
                              d="M0 16C0 14.8954 0.895431 14 2 14C3.10457 14 4 14.8954 4 16C4 17.1046 3.10457 18 2 18C0.895431 18 0 17.1046 0 16Z"
                              fill="currentcolor"
                            />
                            <path
                              d="M7 2C7 0.895431 7.89543 0 9 0C10.1046 0 11 0.895431 11 2C11 3.10457 10.1046 4 9 4C7.89543 4 7 3.10457 7 2Z"
                              fill="currentcolor"
                            />
                            <path
                              d="M7 9C7 7.89543 7.89543 7 9 7C10.1046 7 11 7.89543 11 9C11 10.1046 10.1046 11 9 11C7.89543 11 7 10.1046 7 9Z"
                              fill="currentcolor"
                            />
                            <path
                              d="M7 16C7 14.8954 7.89543 14 9 14C10.1046 14 11 14.8954 11 16C11 17.1046 10.1046 18 9 18C7.89543 18 7 17.1046 7 16Z"
                              fill="currentcolor"
                            />
                            <path
                              d="M14 2C14 0.895431 14.8954 0 16 0C17.1046 0 18 0.895431 18 2C18 3.10457 17.1046 4 16 4C14.8954 4 14 3.10457 14 2Z"
                              fill="currentcolor"
                            />
                            <path
                              d="M14 9C14 7.89543 14.8954 7 16 7C17.1046 7 18 7.89543 18 9C18 10.1046 17.1046 11 16 11C14.8954 11 14 10.1046 14 9Z"
                              fill="currentcolor"
                            />
                            <path
                              d="M14 16C14 14.8954 14.8954 14 16 14C17.1046 14 18 14.8954 18 16C18 17.1046 17.1046 18 16 18C14.8954 18 14 17.1046 14 16Z"
                              fill="currentcolor"
                            />
                          </svg>
                        </Link>
                      </li>
                      <li className="header-btn">
                        <Link className="btn" to="/join_asl_team">
                          Registration
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="mobile-nav-toggler">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 18 18"
                      fill="none">
                      <path
                        d="M0 2C0 0.895431 0.895431 0 2 0C3.10457 0 4 0.895431 4 2C4 3.10457 3.10457 4 2 4C0.895431 4 0 3.10457 0 2Z"
                        fill="currentcolor"
                      />
                      <path
                        d="M0 9C0 7.89543 0.895431 7 2 7C3.10457 7 4 7.89543 4 9C4 10.1046 3.10457 11 2 11C0.895431 11 0 10.1046 0 9Z"
                        fill="currentcolor"
                      />
                      <path
                        d="M0 16C0 14.8954 0.895431 14 2 14C3.10457 14 4 14.8954 4 16C4 17.1046 3.10457 18 2 18C0.895431 18 0 17.1046 0 16Z"
                        fill="currentcolor"
                      />
                      <path
                        d="M7 2C7 0.895431 7.89543 0 9 0C10.1046 0 11 0.895431 11 2C11 3.10457 10.1046 4 9 4C7.89543 4 7 3.10457 7 2Z"
                        fill="currentcolor"
                      />
                      <path
                        d="M7 9C7 7.89543 7.89543 7 9 7C10.1046 7 11 7.89543 11 9C11 10.1046 10.1046 11 9 11C7.89543 11 7 10.1046 7 9Z"
                        fill="currentcolor"
                      />
                      <path
                        d="M7 16C7 14.8954 7.89543 14 9 14C10.1046 14 11 14.8954 11 16C11 17.1046 10.1046 18 9 18C7.89543 18 7 17.1046 7 16Z"
                        fill="currentcolor"
                      />
                      <path
                        d="M14 2C14 0.895431 14.8954 0 16 0C17.1046 0 18 0.895431 18 2C18 3.10457 17.1046 4 16 4C14.8954 4 14 3.10457 14 2Z"
                        fill="currentcolor"
                      />
                      <path
                        d="M14 9C14 7.89543 14.8954 7 16 7C17.1046 7 18 7.89543 18 9C18 10.1046 17.1046 11 16 11C14.8954 11 14 10.1046 14 9Z"
                        fill="currentcolor"
                      />
                      <path
                        d="M14 16C14 14.8954 14.8954 14 16 14C17.1046 14 18 14.8954 18 16C18 17.1046 17.1046 18 16 18C14.8954 18 14 17.1046 14 16Z"
                        fill="currentcolor"
                      />
                    </svg>
                  </div>
                </nav>
              </div>

              <div className="tgmobile__menu">
                <nav className="tgmobile__menu-box">
                  <div className="close-btn">
                    <i className="fas fa-times"></i>
                  </div>
                  <div className="nav-logo">
                    <Link to="/">
                      <img src="Linkssets/img/logo/logo.png" alt="Logo" />
                    </Link>
                  </div>
                  <div className="tgmobile__search">
                    <form action="#">
                      <input type="text" placeholder="Search here..." />
                      <button>
                        <i className="fas fa-search"></i>
                      </button>
                    </form>
                  </div>
                  <div className="tgmobile__menu-outer"></div>
                  <div className="tgmobile__menu-bottom">
                    <div className="contact-info">
                      <ul className="list-wrap">
                        <li>
                          <Link to="mailto:abhhyamsecure@gmail.com">
                            abhhyamsecure@gmail.com
                          </Link>
                        </li>
                        <li>
                          <Link to="tel:9971713967">+91-9971713967</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="social-links">
                      <ul className="list-wrap">
                        <li>
                          <Link to="#">
                            <i className="fab fa-facebook-f"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-twitter"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-instagram"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-linkedin-in"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-youtube"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>
              <div className="tgmobile__menu-backdrop"></div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
