import { useEffect, useState } from "react";
import Weblayout from "../layout/Weblayout";
import axios from "axios";
import RequestCall from "../components/Requestcall";

function Certification() {
  const [data, setdata] = useState([]);
  const getDataList = async () => {
    try {
      const response = await axios.get(
        "https://abhhyamsecure.com/api/api/v1/website/certificate"
      );
      setdata(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getDataList();
  }, []);

  return (
    <Weblayout>
      <>
        <a
          href="#top_bottom"
          className="scroll__top scroll-to-target"
          data-target="html">
          <i className="fas fa-angle-up"></i>
        </a>

        <main className="fix" id="top_bottom">
          <section className="breadcrumb__area breadcrumb__bg about_banner_bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="breadcrumb__content">
                    <h2 className="title">Certification</h2>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="/">Home</a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page">
                          Certification
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="breadcrumb__shape">
              <img src="assets/img/images/breadcrumb_shape01.png" alt="" />
              <img
                src="assets/img/images/breadcrumb_shape02.png"
                alt=""
                className="rightToLeft"
              />
              <img src="assets/img/images/breadcrumb_shape03.png" alt="" />
              <img src="assets/img/images/breadcrumb_shape04.png" alt="" />
              <img
                src="assets/img/images/breadcrumb_shape05.png"
                alt=""
                className="alltuchtopdown"
              />
            </div>
          </section>

          <section
            className="pricing__area pricing__bg"
            data-background="assets/img/bg/pricing_bg.jpg">
            <div className="container">
              <div className="pricing__item-wrap">
                <div className="row justify-content-center">
                  {data.map((item) => {
                    return (
                      <div className="col-lg-4 col-md-6 col-sm-8" key={item.id}>
                        <div className="pricing__box text-center">
                          <div className="pricing__head">
                            <h5 className="title">{item.title}</h5>
                          </div>
                          <div className="pricing__list">
                            <iframe
                              src={item.image}
                              frameborder="0"
                              className="iframe__style"
                              height="300px"
                              width="100%"></iframe>
                          </div>
                          <div className="pricing__btn">
                            <a
                              href={item.image}
                              className="btn"
                              target="_blank">
                              View Certificate
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="pricing__shape-wrap">
              <img
                src="assets/img/images/pricing_shape01.png"
                alt=""
                data-aos="fade-right"
                data-aos-delay="400"
              />
              <img
                src="assets/img/images/pricing_shape02.png"
                alt=""
                data-aos="fade-left"
                data-aos-delay="400"
              />
            </div>
          </section>
          <RequestCall />
        </main>
      </>
    </Weblayout>
  );
}

export default Certification;
