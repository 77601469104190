import RequestCall from "../components/Requestcall";
import Weblayout from "../layout/Weblayout";
import { Link } from "react-router-dom";

function DirectMarketingPaln() {
  return (
    <Weblayout>
      <>
        <a
          href="#top_bottom"
          className="scroll__top scroll-to-target"
          data-target="html">
          <i className="fas fa-angle-up"></i>
        </a>

        <main className="fix" id="top_bottom">
          <section class="breadcrumb__area breadcrumb__bg about_banner_bg">
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                  <div class="breadcrumb__content">
                    <h2 class="title">Marketing Plan</h2>
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                          Marketing Plan
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div class="breadcrumb__shape">
              <img src="assets/img/images/breadcrumb_shape01.png" alt="" />
              <img
                src="assets/img/images/breadcrumb_shape02.png"
                alt=""
                class="rightToLeft"
              />
              <img src="assets/img/images/breadcrumb_shape03.png" alt="" />
              <img src="assets/img/images/breadcrumb_shape04.png" alt="" />
              <img
                src="assets/img/images/breadcrumb_shape05.png"
                alt=""
                class="alltuchtopdown"
              />
            </div>
          </section>

          <section class="services__details-area">
            <div class="container">
              <div class="services__details-wrap">
                <div class="row">
                  <div class="col-70 order-0 order-lg-2">
                    <div className="services__details-top">
                      <div className="row gutter-24 align-items-center">
                        <h2 className="title">
                          {" "}
                          PARA-8: Direct Selling Marketing Plan{" "}
                        </h2>
                        <div className="col-49 mb-2">
                          <div className="services__details-thumb services__details-thumb-two">
                            <img
                              src="assets/img/marketing_plan/marketing/1.png"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-51">
                          <div className="services__details-top-content">
                            <p>
                              A direct selling markeng plan outlines strategies
                              and taccs for promong and selling products or
                              services. It includes product analysis, target
                              market idenficaon, markeng strategies
                              (online/offline), lead generaon, training/support,
                              evaluaon metrics, budgeng, compliance, and
                              implementaon meline. The Abhhyam Secure Private
                              Limited (ASPL) markeng plan aims to maximize sales
                              incenves and pensionary benefits funds on each HRM
                              and third-party payrolls service purchase by the
                              Enty Member Company (EMC)
                            </p>
                            <p>
                              Direct selling leverage income refers to the
                              ability to generate huge income by leveraging
                              direct selling networks. Direct selling involves
                              selling products or services directly to
                              consumers.
                            </p>
                            <p>
                              However, Abhhyam Secure Life (ASL) network
                              leverage allows for exponenal income growth as the
                              network expands, providing the opportunity to earn
                              a significant and potenally income.
                            </p>
                          </div>
                        </div>
                        <p>
                          Through this iniave, now, over a period of me it will
                          be possible for the services sector to offer and earn
                          a leverage advantage by geng incenves on services and
                          pensionary bond and other service benefits for
                          employees.
                        </p>
                        <p>
                          Network markeng, also known as mul-level markeng
                          (MLM), has experienced significant growth in recent
                          years in India. The rise of social media and online
                          plaorms has provided a conducive environment for
                          network markeng businesses to expand their reach and
                          engage with a broader audience. Hence, Abhhyam Secure
                          Life (ASL) is planning to put the service industry
                          through this network channel to earn Point Volumes
                          (PV) on each and every service purchase, resulng in a
                          regular incenve for buyer and pensionary bond and
                          other service benefits generaon for their employees.
                        </p>
                      </div>
                    </div>
                    <div className="services__details-top">
                      <div className="row gutter-24 align-items-center">
                        <h2 className="title">
                          {" "}
                          PARA-9: Direct selling and challenges and solutions :{" "}
                        </h2>

                        <div className="col-51">
                          <div className="services__details-top-content">
                            <p>
                              The Networking markeng or direct selling concept
                              is accepted by very limited populaons due to the
                              various reasons. However, Abhhyam Secure Life
                              (ASL) came out with soluons for each challenge of
                              the concept. Presently, direct selling provides a
                              very limited offer of products in the services
                              industry, whereas, Abhhyam Secure Life (ASL) gives
                              an open choice to the employer to buy the HR and
                              Third-Party payroll services as per his choice
                              available on the portal. Moreover, price
                              challenges are also being faced by the services
                              sector.
                            </p>
                          </div>
                        </div>
                        <div className="col-49 mb-2">
                          <div className="services__details-thumb services__details-thumb-two">
                            <img
                              src="assets/img/marketing_plan/marketing/2.png"
                              alt=""
                            />
                          </div>
                        </div>
                        <p>
                          Most Important is that the Abhhyam Secure Life (ASL)
                          markeng plan with a minimum 96 months and maximum 144
                          months fixed tenure, so that every Enty Member Company
                          (EMC) may able to get the proposed pensionary benefits
                          for employees and incenves for self against the
                          purchase of HR and Third-party pay rolls services from
                          the Abhhyam Secure Life (ASL) markeng plan.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-30">
                    <aside className="services__sidebar">
                      <div className="sidebar__widget sidebar__widget-three">
                        <div className="sidebar__cat-list-two sidebar__cat-list-three">
                          <ul className="list-wrap">
                            <li>
                              <Link to="/initiative">
                                Initiative{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/direct_marketing_plan">
                                Marketing Plan{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/advantages">
                                Advantages{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/incentives">
                                Incentives{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/members">
                                Members{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/general">
                                General{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="sidebar__widget sidebar__widget-two">
                        <div className="sidebar__contact sidebar__contact-two sidebar_head_contact">
                          <h2 className="title">
                            If You Need Any Help Contact With Us
                          </h2>
                          <Link to="tel:0123456789" className="btn">
                            <i className="flaticon-phone-call"></i>
                            +91-9971713967
                          </Link>
                        </div>
                      </div>
                      <div className="sidebar__widget sidebar__widget-three">
                        <h4 className="sidebar__widget-title">Brochure</h4>
                        <div className="sidebar__brochure sidebar__brochure-two">
                          <p>Abhhyam Secure Life </p>
                          <a
                            href="/assets/img/marketing_plan/abhhyam_secure_life.pdf"
                            download={""}>
                            <i className="far fa-file-pdf"></i>PDF. Download
                          </a>
                        </div>
                      </div>
                    </aside>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <RequestCall />
        </main>
      </>
    </Weblayout>
  );
}

export default DirectMarketingPaln;
