import RequestCall from "../components/Requestcall";
import Weblayout from "../layout/Weblayout";
import { Link } from "react-router-dom";

function Advantages() {
  return (
    <Weblayout>
      <>
        <a
          href="#top_bottom"
          className="scroll__top scroll-to-target"
          data-target="html">
          <i className="fas fa-angle-up"></i>
        </a>

        <main className="fix" id="top_bottom">
          <section className="breadcrumb__area breadcrumb__bg about_banner_bg">
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                  <div class="breadcrumb__content">
                    <h2 class="title">Advantages</h2>
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                          Advantages
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div class="breadcrumb__shape">
              <img src="assets/img/images/breadcrumb_shape01.png" alt="" />
              <img
                src="assets/img/images/breadcrumb_shape02.png"
                alt=""
                class="rightToLeft"
              />
              <img src="assets/img/images/breadcrumb_shape03.png" alt="" />
              <img src="assets/img/images/breadcrumb_shape04.png" alt="" />
              <img
                src="assets/img/images/breadcrumb_shape05.png"
                alt=""
                class="alltuchtopdown"
              />
            </div>
          </section>

          <section class="services__details-area">
            <div class="container">
              <div class="services__details-wrap">
                <div class="row">
                  <div class="col-70 order-0 order-lg-2">
                    <div class="services__details-top">
                      <div class="row gutter-24 align-items-center">
                        <div class="col-51">
                          <div class="services__details-top-content">
                            <h2 class="title">
                              PARA-10: Abhhyam Secure Life (ASL) Advantage
                            </h2>
                            <p>
                              Abhhyam Secure Life (ASL) is designed and planned
                              to generate the following advantages for the Enty
                              Members Company (EMC) as well as for their
                              employees for smooth service tenure and secure
                              life on the buying of HRM and third-party pay
                              rolls services:
                            </p>
                            <div class="about__list-box about__list-box-three">
                              <ul class="list-wrap">
                                <li>
                                  <i class="flaticon-arrow-button"></i>To meet
                                  the need of enes, organizaon about safety and
                                  security requirement with human resources,
                                  manpower services guidance and assistance as
                                  per his demand.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="col-49">
                          <div class="services__details-thumb services__details-thumb-two">
                            <img
                              src="assets/img/marketing_plan/advantage/1.png"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="services__details-content">
                      <div class="about__list-box about__list-box-three">
                        <ul class="list-wrap">
                          <li>
                            <i class="flaticon-arrow-button"></i>As per the
                            capacity of the enty/organizaons, aracve monthly
                            leverage Incenves for the employer and the
                            Pensionary and other services benefits for employees
                            offered on the regular HRM and third party pay roll
                            services of ASL direct selling markeng plan
                          </li>
                          <li>
                            <i class="flaticon-arrow-button"></i>As per the
                            Insurance and Health Insurance facility for the
                            employee on case-to-case basis with the consent of
                            the employer.
                          </li>
                          <li>
                            <i class="flaticon-arrow-button"></i>Pensionary and
                            services benefits for each employee on the
                            recommendaons of the employer on compleon of minimum
                            96 months or proposed maximum scheme tenure is 144
                            months.
                          </li>
                          <li>
                            <i class="flaticon-arrow-button"></i>Technology
                            support and guidance.
                          </li>
                          <li>
                            <i class="flaticon-arrow-button"></i>Training
                            support and educaon support on the subject.
                          </li>
                          <li>
                            <i class="flaticon-arrow-button"></i>Legal guidance
                            and media
                          </li>
                        </ul>
                      </div>
                      <h2 class="title mt-3">
                        PARA-11: Scope of services and support
                      </h2>
                      <p>
                        The plan and benefits are proposed through Abhhyam
                        Secure Life (ASL) are as under:
                      </p>
                      <div class="about__list-box-three">
                        <ul style={{ listStyle: "upper-alpha" }}>
                          <li>
                            The 80% of the revenue will be ulized for the of the
                            members of the scheme as well as welfare of
                            Employer, Employees and rep members.
                          </li>
                          <li>
                            The HRM and third party pay roll services of the ASL
                            are covered the following area of responsibility
                            <ul style={{ listStyle: "lower-alpha" }}>
                              <li>Online Digital ASL HRM Portal.</li>
                              <li>
                                Digital Aendance facility through ASL Portal.
                              </li>
                              <li>
                                Third party pay rolls services through HRM
                                Portal.
                              </li>
                              <li>
                                Employee Salary Disbursement on behalf of
                                Entity.
                              </li>
                              <li>PF and ESI compliance and documentation.</li>
                              <li>
                                Facility to keep update of expenses through ASL
                                Portal.
                              </li>
                              <li>
                                Maintenance of Duty and Leave accounts of the
                                Employee through ASL HRM Portal.
                              </li>
                              <li>
                                Facility of Leave Travel Fund after completion
                                of 24 months services.
                              </li>
                              <li>
                                Children Education scholarship for 2 children of
                                the Employees of the entity.
                              </li>
                              <li>
                                Lump-sum gratuity on completion of minimum 8
                                years of services.
                              </li>
                              <li>
                                Providing details for each employee through ASL
                                Account Mob App.
                              </li>
                            </ul>
                          </li>
                          <li>
                            All ASL ID accounts are entitled for Point Volume
                            against each successful transaction.
                          </li>
                          <li>
                            The billing of each HRM service taken by the Entity
                            Member Company (EMC) will be done as per law through
                            Entity Member ASL Account ID (EMA) only.
                          </li>
                          <li>
                            On every successful transaction of Entity Member
                            Company (EMC), Point Volume (PV) will be allocated
                            to Entity Member Account (EMA).
                          </li>
                          <li>
                            Entity Member Fund (EMF) will be released to the
                            Entity Member Company (EMC) till maximum 144-month
                            tenure on monthly basis of each employee service.
                          </li>
                          <li>
                            The employee is eligible for Secure Life Fund (SLF)
                            through which various types of pensionary and
                            services benefits will be kept and released on due
                            date and time for happy and secure the life.
                          </li>
                          <li>
                            The maximum tenure of the scheme is 144-month time,
                            however, on completion of 96 months of the scheme,
                            member can apply for Voluntary Quit Service (VQS)
                            through his employer.
                          </li>
                          <li>
                            On maturity of 144 months, ASL ID Account are
                            entitled for Pensionary Benefits with Pension Bond
                            and will be provided from any insurance company as
                            per the availability of the SLB funds in his ASL ID
                            Account, however, the maximum pension bond would be
                            equal to the last salary drawn by the employee or
                            maximum Rs.50,000/- capping.
                          </li>
                        </ul>
                      </div>

                      <h2 className="title-two mt-3">
                        PARA-13: ASL Incentives benefits:
                      </h2>
                      <div className="col-100 order-0 order-md-0 mb-2">
                        <div className="services__details-inner-img">
                          <img
                            src="assets/img/marketing_plan/advantage/3.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <p>
                        The aim of the Abhhyam Secure Life is “Secure Citizen –
                        Secure Country” therefore, various types of incentives
                        and pensionary and services benefits has been planned to
                        support the employer as well as to take care of each
                        employee.
                      </p>
                      <div class="about__list-box-three">
                        <ul style={{ listStyle: "upper-alpha" }}>
                          <li>
                            Monthly Incentive, pensionary and other services
                            benefits: 80% of the revenue will be distributed
                            among the all the registered members and entitled
                            for monthly incentives, pensionary and other
                            services benefits. There are three types of
                            membership in Abhhyam Secure Life direct selling
                            marketing plan.
                            <ul style={{ listStyle: "lower-roman" }}>
                              <li>
                                Entity Member Company (EMC): Any entity/
                                company/ organization registered and buy
                                services under the ASL Scheme will be an EMC and
                                entitled for the Entity Employee Incentive (EEI)
                                and Entity Protection Incentive (EPI).
                              </li>
                              <li>
                                Entity Member Account (EMA): Each employee will
                                be allocated one ASL ID Account by the employer
                                to get the pensionary and service benefits as
                                per the policy plan.
                              </li>
                              <li>
                                Entity Rep Member (ERM): ERM will support the
                                sales and marketing for ASL and will be eligible
                                for referral PV on each Entity registration.
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-30">
                    <aside className="services__sidebar">
                      <div className="sidebar__widget sidebar__widget-three">
                        <div className="sidebar__cat-list-two sidebar__cat-list-three">
                          <ul className="list-wrap">
                            <li>
                              <Link to="/initiative">
                                Initiative{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/direct_marketing_plan">
                                Marketing Plan{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/advantages">
                                Advantages{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/incentives">
                                Incentives{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/members">
                                Members{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/general">
                                General{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="sidebar__widget sidebar__widget-two">
                        <div className="sidebar__contact sidebar__contact-two sidebar_head_contact">
                          <h2 className="title">
                            If You Need Any Help Contact With Us
                          </h2>
                          <Link to="tel:0123456789" className="btn">
                            <i className="flaticon-phone-call"></i>
                            +91-9971713967
                          </Link>
                        </div>
                      </div>
                      <div className="sidebar__widget sidebar__widget-three">
                        <h4 className="sidebar__widget-title">Brochure</h4>
                        <div className="sidebar__brochure sidebar__brochure-two">
                          <p>Abhhyam Secure Life </p>
                          <a
                            href="/assets/img/marketing_plan/abhhyam_secure_life.pdf"
                            download={""}>
                            <i className="far fa-file-pdf"></i>PDF. Download
                          </a>
                        </div>
                      </div>
                    </aside>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <RequestCall />
        </main>
      </>
    </Weblayout>
  );
}

export default Advantages;
