import RequestCall from "../components/Requestcall";
import Weblayout from "../layout/Weblayout";

function Founder() {
  return (
    <Weblayout>
      <>
        <a
          href="#top_bottom"
          className="scroll__top scroll-to-target"
          data-target="html">
          <i className="fas fa-angle-up"></i>
        </a>

        <main className="fix" id="top_bottom">
          <section class="breadcrumb__area breadcrumb__bg about_banner_bg">
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                  <div class="breadcrumb__content">
                    <h2 class="title">Founder Team</h2>
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <a href="/">Home</a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                          Founder Team
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div class="breadcrumb__shape">
              <img src="assets/img/images/breadcrumb_shape01.png" alt="" />
              <img
                src="assets/img/images/breadcrumb_shape02.png"
                alt=""
                class="rightToLeft"
              />
              <img src="assets/img/images/breadcrumb_shape03.png" alt="" />
              <img src="assets/img/images/breadcrumb_shape04.png" alt="" />
              <img
                src="assets/img/images/breadcrumb_shape05.png"
                alt=""
                class="alltuchtopdown"
              />
            </div>
          </section>

          <section class="team__area-four">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-6">
                  <div class="section-title text-center mb-40">
                    <span class="sub-title">ASL TEAM</span>
                    <h2 class="title">
                      Team Behind the <br /> Abhhyam Secured Life
                    </h2>
                  </div>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                  <div class="team__item-four shine-animate-item">
                    <div class="team__thumb-four shine-animate">
                      <img src="assets/img/team/team_img01.jpg" alt="" />
                    </div>
                    <div class="team__content-four">
                      <h2 class="title">
                        <a href="#">Puran Chand Arya, (Retd) CRPF</a>
                      </h2>
                      <span>Founder Chairman</span>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                  <div class="team__item-four shine-animate-item">
                    <div class="team__thumb-four shine-animate">
                      <img src="assets/img/team/team_img02.jpg" alt="" />
                    </div>
                    <div class="team__content-four">
                      <h2 class="title">
                        <a href="#">Er. Navin Kumar Sah</a>
                      </h2>
                      <span>Managing Director</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <RequestCall />
        </main>
      </>
    </Weblayout>
  );
}

export default Founder;
