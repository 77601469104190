import Weblayout from "../layout/Weblayout";
import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import RequestCall from "../components/Requestcall";

function Downloads() {
  const [data, setdata] = useState([]);
  const getDataList = async () => {
    try {
      const response = await axios.get(
        "https://abhhyamsecure.com/api/api/v1/website/document"
      );
      setdata(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getDataList();
  }, []);

  return (
    <Weblayout>
      <>
        <a
          href="#top_bottom"
          className="scroll__top scroll-to-target"
          data-target="html">
          <i className="fas fa-angle-up"></i>
        </a>

        <main className="fix" id="top_bottom">
          <section className="breadcrumb__area breadcrumb__bg about_banner_bg">
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                  <div class="breadcrumb__content">
                    <h2 class="title">Downloads</h2>
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                          Downloads
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div class="breadcrumb__shape">
              <img src="assets/img/images/breadcrumb_shape01.png" alt="" />
              <img
                src="assets/img/images/breadcrumb_shape02.png"
                alt=""
                class="rightToLeft"
              />
              <img src="assets/img/images/breadcrumb_shape03.png" alt="" />
              <img src="assets/img/images/breadcrumb_shape04.png" alt="" />
              <img
                src="assets/img/images/breadcrumb_shape05.png"
                alt=""
                class="alltuchtopdown"
              />
            </div>
          </section>

          <section
            className="pricing__area pricing__bg"
            data-background="assets/img/bg/pricing_bg.jpg">
            <div className="container">
              <div className="pricing__item-wrap">
                <div className="row justify-content-center">
                  {data.map((item) => {
                    return (
                      <div className="col-lg-4 col-md-6 col-sm-8" key={item.id}>
                        <div className="pricing__box text-center">
                          <div className="pricing__head">
                            <h5 className="title">{item.title}</h5>
                          </div>
                          <div className="pricing__list">
                            <iframe
                              src={item.image}
                              frameborder="0"
                              className="iframe__style"
                              height="300px"
                              width="100%"></iframe>
                          </div>
                          <div className="pricing__btn">
                            <a
                              href={item.image}
                              className="btn"
                              target="_blank">
                              <i className="fa fa-download"></i> &nbsp; Download
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="pricing__shape-wrap">
              <img
                src="assets/img/images/pricing_shape01.png"
                alt=""
                data-aos="fade-right"
                data-aos-delay="400"
              />
              <img
                src="assets/img/images/pricing_shape02.png"
                alt=""
                data-aos="fade-left"
                data-aos-delay="400"
              />
            </div>
          </section>

          <RequestCall />
        </main>
      </>
    </Weblayout>
  );
}

export default Downloads;
