import RequestCall from "../components/Requestcall";
import Weblayout from "../layout/Weblayout";
import { Link } from "react-router-dom";

function Members() {
  return (
    <Weblayout>
      <>
        <a
          href="#top_bottom"
          className="scroll__top scroll-to-target"
          data-target="html">
          <i className="fas fa-angle-up"></i>
        </a>

        <main className="fix" id="top_bottom">
          <section className="breadcrumb__area breadcrumb__bg about_banner_bg">
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                  <div class="breadcrumb__content">
                    <h2 class="title">Members</h2>
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                          Members
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div class="breadcrumb__shape">
              <img src="assets/img/images/breadcrumb_shape01.png" alt="" />
              <img
                src="assets/img/images/breadcrumb_shape02.png"
                alt=""
                class="rightToLeft"
              />
              <img src="assets/img/images/breadcrumb_shape03.png" alt="" />
              <img src="assets/img/images/breadcrumb_shape04.png" alt="" />
              <img
                src="assets/img/images/breadcrumb_shape05.png"
                alt=""
                class="alltuchtopdown"
              />
            </div>
          </section>

          <section class="services__details-area">
            <div class="container">
              <div class="services__details-wrap">
                <div class="row">
                  <div class="col-70 order-0 order-lg-2">
                    <div class="services__details-content">
                      <div class="services__details-top">
                        <div class="row gutter-24 align-items-center">
                          <div class="col-51">
                            <div class="services__details-top-content">
                              <h2 class="title">
                                PARA-12: Registration of Entity Member Company
                              </h2>
                              <p>
                                Any legal company/institutions/organizations are
                                eligible to be registered as Entity Member
                                Company (EMC) to get the services offered by the
                                Abhhyam Secure Life (ASL) plan.
                              </p>
                              <div class="about__list-box about__list-box-three">
                                <ul class="list-wrap">
                                  <li>
                                    <i class="flaticon-arrow-button"></i>No
                                    registration fees, can submit request
                                    proposal at www.AbhhyamSecure.com for
                                    process.
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="col-49">
                            <div class="services__details-thumb services__details-thumb-two">
                              <img
                                src="assets/img/marketing_plan/advantage/2.png"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                        <div class="about__list-box about__list-box-three">
                          <ul class="list-wrap">
                            <li>
                              <i class="flaticon-arrow-button"></i> On the first
                              invoice of the HRM and third party pay rolls
                              services contract from ASL, the ASL Account number
                              will be allocated for each number of employee
                              account created by the Entity Member Company
                              (EMC).
                            </li>
                            <li>
                              <i class="flaticon-arrow-button"></i> Entity
                              Member Company (EMC) has to declare its total
                              number of employees at the time of registration to
                              create an equal number of ASL ID Accounts for each
                              employee to facilitate Pensionary and other
                              services benefits.
                            </li>
                            <li>
                              <i class="flaticon-arrow-button"></i> All process
                              is digitally designed.
                            </li>
                          </ul>
                        </div>
                      </div>
                      <h2 className="title-two mt-3">
                        PARA-25: ASL Entity Representative Member (ERM)
                      </h2>
                      <p>
                        All individual sales and marketing leaders or marketing
                        company want to represent the Abhhyam Secure Life can
                        registered as Abhhyam Secure Life Entity Rep Member
                        (ERM). Only Registered ERM will be entitled for the
                        following benefits:
                      </p>
                      <div class="about__list-box-three">
                        <ul style={{ listStyle: "upper-alpha" }}>
                          <li>
                            On the recommendations of Entity Rep Member (ERM),
                            if any EMC registered with Abhhyam Secure Life (ASL)
                            and get the HR and third-party payroll services as
                            per ASL Policy. In that case Entity Rep Member (ERM)
                            is eligible for 2% Point Volume for each and every
                            ASL Account created by the Entity Member Company
                            (EMC) for their employees.
                          </li>
                          <li>
                            b) On month closing, total PV earned by the ERM will
                            be converted as Rep Member Fund (RMF) amount.
                          </li>
                          <li>
                            50% of the Rep Member Fund (RMF) amount will be
                            released to Rep Member Bonus (RMB).
                          </li>
                          <li>
                            The remaining 50% will be kept as a Pension Bond
                            Fund (PBF), maximum Rs.5 crore Pension Bond Fund
                            (PBF) is allowed during 120 months tenure.
                          </li>
                          <li>
                            On maturity of 144 months tenure time, the ERM
                            account will be removed from the Abhhyam Secure Life
                            Plan (ASL) and one new ASL ID account will be
                            automatic active from the last available ID, if any
                            Entity Member Company (EMC) introduced by him is
                            active and taking services.
                          </li>
                          <li>
                            There is no capping of maximum PV for Entity Rep
                            Member (ASM) ASL account and one new ASL ID account
                            will be created on each 200 PV, however, maximum
                            capping for Rep Member Bonus (RMB) is Rs.10 lakhs pm
                            during the 144 months tenure and equal amount will
                            be deposited as Pension Bond Fund (PBF) subject to
                            capping of Rs.5 crore as RMF. On achieving maximum
                            PBF, a pension bond immediately will be released
                            from any national insurance company.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-30">
                    <aside className="services__sidebar">
                      <div className="sidebar__widget sidebar__widget-three">
                        <div className="sidebar__cat-list-two sidebar__cat-list-three">
                          <ul className="list-wrap">
                            <li>
                              <Link to="/initiative">
                                Initiative{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/direct_marketing_plan">
                                Marketing Plan{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/advantages">
                                Advantages{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/incentives">
                                Incentives{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/members">
                                Members{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/general">
                                General{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="sidebar__widget sidebar__widget-two">
                        <div className="sidebar__contact sidebar__contact-two sidebar_head_contact">
                          <h2 className="title">
                            If You Need Any Help Contact With Us
                          </h2>
                          <Link to="tel:0123456789" className="btn">
                            <i className="flaticon-phone-call"></i>
                            +91-9971713967
                          </Link>
                        </div>
                      </div>
                      <div className="sidebar__widget sidebar__widget-three">
                        <h4 className="sidebar__widget-title">Brochure</h4>
                        <div className="sidebar__brochure sidebar__brochure-two">
                          <p>Abhhyam Secure Life </p>
                          <a
                            href="/assets/img/marketing_plan/abhhyam_secure_life.pdf"
                            download={""}>
                            <i className="far fa-file-pdf"></i>PDF. Download
                          </a>
                        </div>
                      </div>
                    </aside>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <RequestCall />
        </main>
      </>
    </Weblayout>
  );
}

export default Members;
