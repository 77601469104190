import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const PopupComponent = ({ isOpen, setIsOpen }) => {
  const popupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  console.log(isOpen);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div>
      {isOpen && (
        <div ref={popupRef}>
          <div className={`offCanvas__info active`}>
            <div
              className="offCanvas__close-icon menu-close"
              onClick={() => setIsOpen(false)}>
              <button>
                <i className="far fa-window-close"></i>
              </button>
            </div>
            <div className="offCanvas__logo mb-30">
              <Link to="#">
                <img src="assets/img/logo/logo.png" alt="Logo" />
              </Link>
            </div>
            <div className="offCanvas__side-info mb-30">
              <div className="contact-list mb-30">
                <h4>Office Address</h4>
                <p>C-7/82, Sector-8, Rohini, New Delhi-110085</p>
              </div>
              <div className="contact-list mb-30">
                <h4>Phone Number</h4>
                <p>+91-9971713967</p>
              </div>
              <div className="contact-list mb-30">
                <h4>Email Address</h4>
                <p>abhhyamsecure@gmail.com</p>
              </div>
            </div>
            <div className="offCanvas__social-icon mt-30">
              <Link to="#">
                <i className="fab fa-facebook-f"></i>
              </Link>
              <Link to="#">
                <i className="fab fa-twitter"></i>
              </Link>
              <Link to="#">
                <i className="fab fa-google-plus-g"></i>
              </Link>
              <Link to="#">
                <i className="fab fa-instagram"></i>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PopupComponent;
