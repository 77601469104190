import RequestCall from "../components/Requestcall";
import Weblayout from "../layout/Weblayout";
import { Link } from "react-router-dom";

function General() {
  return (
    <Weblayout>
      <>
        <a
          href="#top_bottom"
          className="scroll__top scroll-to-target"
          data-target="html">
          <i className="fas fa-angle-up"></i>
        </a>

        <main className="fix" id="top_bottom">
          <section className="breadcrumb__area breadcrumb__bg about_banner_bg">
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                  <div class="breadcrumb__content">
                    <h2 class="title">Members</h2>
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                          Members
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div class="breadcrumb__shape">
              <img src="assets/img/images/breadcrumb_shape01.png" alt="" />
              <img
                src="assets/img/images/breadcrumb_shape02.png"
                alt=""
                class="rightToLeft"
              />
              <img src="assets/img/images/breadcrumb_shape03.png" alt="" />
              <img src="assets/img/images/breadcrumb_shape04.png" alt="" />
              <img
                src="assets/img/images/breadcrumb_shape05.png"
                alt=""
                class="alltuchtopdown"
              />
            </div>
          </section>

          <section class="services__details-area">
            <div class="container">
              <div class="services__details-wrap">
                <div class="row">
                  <div class="col-70 order-0 order-lg-2">
                    <div class="services__details-content">
                      <h2 className="title-two mt-3">
                        PARA-26: Notes and Disclaimer
                      </h2>
                      <div class="about__list-box-three">
                        <ul style={{ listStyle: "decimal" }}>
                          <li>
                            Registration is free for all and the company does
                            not ask for any registration fee.
                          </li>
                          <li>
                            Calculations of monthly Funds / Incentives and
                            rewards will be carried out by the software systems
                            only.
                          </li>
                          <li>
                            To remain active, EMCs/ERMs must SPV of a minimum of
                            200 PV every month.
                          </li>
                          <li>
                            EMC/EMA (ASL Account) /ERM will get all Incentive,
                            Bonus, pensionary and service benefits fund on the
                            basis of EMC regular third party pay rolls and HRM
                            service fee for each employee, which is calculated
                            and given to EMC/ EMA (ASL Account)/ ERM according
                            to generation achieved in a certain month shown in
                            the below table:
                          </li>
                          <table className="table table-bordered">
                            <thead>
                              <tr className="table-danger">
                                <th>Generation Level</th>
                                <th>
                                  Generation Level Incentive Percentage (LIP)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Generation 20 </td>
                                <td>1.00</td>
                              </tr>
                              <tr className="table-success">
                                <td>Generation 19 </td>
                                <td>1.50</td>
                              </tr>
                              <tr>
                                <td>Generation 18 </td>
                                <td>2.00</td>
                              </tr>
                              <tr className="table-success">
                                <td>Generation 17 </td>
                                <td>2.50</td>
                              </tr>
                              <tr>
                                <td>Generation 16 </td>
                                <td>2.50</td>
                              </tr>
                              <tr className="table-success">
                                <td>Generation 15 </td>
                                <td>2.50</td>
                              </tr>
                              <tr>
                                <td>Generation 14 </td>
                                <td>3.00</td>
                              </tr>
                              <tr className="table-success">
                                <td>Generation 13 </td>
                                <td>3.00</td>
                              </tr>
                              <tr>
                                <td>Generation 12 </td>
                                <td>3.00</td>
                              </tr>
                              <tr className="table-success">
                                <td>Generation 11 </td>
                                <td>3.00</td>
                              </tr>
                              <tr>
                                <td>Generation 10 </td>
                                <td>4.00</td>
                              </tr>
                              <tr className="table-success">
                                <td>Generation 9 </td>
                                <td>4.00</td>
                              </tr>
                              <tr>
                                <td>Generation 8 </td>
                                <td>4.00</td>
                              </tr>
                              <tr className="table-success">
                                <td>Generation 7 </td>
                                <td>4.00</td>
                              </tr>
                              <tr>
                                <td>Generation 6 </td>
                                <td>4.00</td>
                              </tr>
                              <tr className="table-success">
                                <td>Generation 5 </td>
                                <td>5.00</td>
                              </tr>
                              <tr>
                                <td>Generation 4 </td>
                                <td>8.00</td>
                              </tr>
                              <tr className="table-success">
                                <td>Generation 3 </td>
                                <td>10.00</td>
                              </tr>
                              <tr>
                                <td>Generation 2 </td>
                                <td>13.00</td>
                              </tr>
                              <tr className="table-success">
                                <td>Generation 1 </td>
                                <td>20.00</td>
                              </tr>
                            </tbody>
                          </table>
                          <li>
                            Active EMCs/EMAs (ASL Account) /ERMs can earn all
                            monthly Incentive, Bonus, pensionary and service
                            benefits fund up to 144 months from the Date of
                            joining (creating ASL Account) or the date when the
                            EMC Employee SLB Fund start accumulated and after
                            that pension bond fund accumulation will stop as and
                            when employee quit the service, however, proposed
                            all Incentive, Bonus, pensionary and service
                            benefits fund will be allowed till 144 months or
                            till any ASL Account is active and generating PV, if
                            not achieved by EMC due to non-sale PV.
                          </li>
                          <li>
                            The maximum threshold limit for EEI is last salary
                            drawn by the ASL Account holder is salary + PF + ESI
                            + Bonus + emoluments + incentive paid by the
                            employer.
                          </li>
                          <li>
                            All necessary tax deductions from earned incentives
                            bonuses & rewards would be made as per the Govt.
                            laws.
                          </li>
                          <li>
                            All examples & calculations given herein are just
                            for the reader to understand the purpose. All the
                            calculations work is done on a pro-rata basis.
                          </li>
                          <li>
                            Abhhyam Secure Life Plan is not an overnight
                            millionaire- making program, it is a process of
                            direct selling marketing plan for minimum 96 months
                            and maximum144 months tenure to secure the life of
                            employee and some incentive for employer.
                          </li>
                          <li>
                            The incentives, pensionary and service benefits from
                            this plan are linked with regular third party pay
                            roll and HR services taken by the employer and duty
                            attendance of employees till maturity of the scheme
                            as per terms & conditionsgivenonthewebsite:
                            www.AbhhyamSecure.com.
                          </li>
                          <li>
                            Grievances or complaints, if any, shall be resolved
                            by the Grievance Redressal Mechanism provided by the
                            company at website or through arbitration as per the
                            laws of the land.
                          </li>
                          <li>
                            Disputes if any will be resolved in the legal
                            jurisdiction of Delhi courts (Delhi, India) only.
                          </li>
                          <li>
                            All rights reserved. The company may change/amend/
                            alter/update any income or payment calculation
                            method without any prior information. For updates,
                            please visit our website: www.AbhhyamSecure.com.
                          </li>
                          <li>
                            Disclaimer – An EMC’s/EMAs (ASL Account)/ERM’s
                            success of earing incentives and pensionary and
                            services benefits depends in great part upon his or
                            her regular involvement of services performance as
                            per the requirement of employer. Becoming an
                            EMC/EMA/ERM of www.AbhhyamSecure.com is not a
                            guarantee of incentives and pensionary and services
                            benefits to secure life. The average income from the
                            Secure life Plan has not been established. This
                            explanation of the Secure Life Plan is a description
                            of how incentives and pensionary and services
                            benefits will be generated and can be earned under
                            the Plan by dedicated regular duty and regular
                            services taken by the employer. It is for
                            illustrative purposes only. There are no guarantees,
                            warranties, or assurances that any level of
                            incentives and pensionary benefits, or success will
                            be earned or attained by any EMC/EMA/ERM. All
                            EMCs/EMAs/ERMs are responsible for meeting all Point
                            Value (PV) and other requirements, qualifications,
                            and/or deadlines applicable to them. Actual results
                            will vary and result from various factors such as
                            expertise, ability, motivation, and time spent
                            promoting and selling Secure Life Plan.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-30">
                    <aside className="services__sidebar">
                      <div className="sidebar__widget sidebar__widget-three">
                        <div className="sidebar__cat-list-two sidebar__cat-list-three">
                          <ul className="list-wrap">
                            <li>
                              <Link to="/initiative">
                                Initiative{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/direct_marketing_plan">
                                Marketing Plan{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/advantages">
                                Advantages{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/incentives">
                                Incentives{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/members">
                                Members{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/general">
                                General{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="sidebar__widget sidebar__widget-two">
                        <div className="sidebar__contact sidebar__contact-two sidebar_head_contact">
                          <h2 className="title">
                            If You Need Any Help Contact With Us
                          </h2>
                          <Link to="tel:0123456789" className="btn">
                            <i className="flaticon-phone-call"></i>
                            +91-9971713967
                          </Link>
                        </div>
                      </div>
                      <div className="sidebar__widget sidebar__widget-three">
                        <h4 className="sidebar__widget-title">Brochure</h4>
                        <div className="sidebar__brochure sidebar__brochure-two">
                          <p>Abhhyam Secure Life </p>
                          <a
                            href="/assets/img/marketing_plan/abhhyam_secure_life.pdf"
                            download={""}>
                            <i className="far fa-file-pdf"></i>PDF. Download
                          </a>
                        </div>
                      </div>
                    </aside>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <RequestCall />
        </main>
      </>
    </Weblayout>
  );
}

export default General;
