import { useState } from "react";
import Weblayout from "../layout/Weblayout";
import { json, Link } from "react-router-dom";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let body = JSON.stringify({
        name: name,
        email: email,
        phone: phone,
        message: message,
        type: "CONTACT",
      });
      const response = await axios.post(
        "https://abhhyamsecure.com/api/api/v1/website/submit-contact",
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      document.getElementById("contact-form").reset();
      toast.success(response.data.msg);
      setName("");
      setEmail("");
      setPhone("");
      setMessage("");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Weblayout>
      <>
        <Toaster position="top-right" reverseOrder={false} />
        <a
          href="#top_bottom"
          className="scroll__top scroll-to-target"
          data-target="html">
          <i className="fas fa-angle-up"></i>
        </a>

        <main className="fix" id="top_bottom">
          <section className="breadcrumb__area breadcrumb__bg about_banner_bg">
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                  <div class="breadcrumb__content">
                    <h2 class="title">Contact With Us</h2>
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                          Contact
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div class="breadcrumb__shape">
              <img src="assets/img/images/breadcrumb_shape01.png" alt="" />
              <img
                src="assets/img/images/breadcrumb_shape02.png"
                alt=""
                class="rightToLeft"
              />
              <img src="assets/img/images/breadcrumb_shape03.png" alt="" />
              <img src="assets/img/images/breadcrumb_shape04.png" alt="" />
              <img
                src="assets/img/images/breadcrumb_shape05.png"
                alt=""
                class="alltuchtopdown"
              />
            </div>
          </section>

          <section class="contact__area">
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="contact-map">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3499.518769192994!2d77.12270127529187!3d28.704038675625824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d03df8bcefb8f%3A0x6751797da49d642c!2sC%20Block%20Rd%2C%20Rohini%2C%20Delhi%2C%20110085!5e0!3m2!1sen!2sin!4v1721282935697!5m2!1sen!2sin"
                      width="600"
                      height="450"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"></iframe>
                  </div>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-lg-5">
                  <div class="contact__content">
                    <div class="section-title mb-35">
                      <h2 class="title">How can we help you?</h2>
                    </div>
                    <div class="contact__info">
                      <ul class="list-wrap">
                        <li>
                          <div class="icon">
                            <i class="flaticon-pin"></i>
                          </div>
                          <div class="content">
                            <h4 class="title">Address</h4>
                            <p>
                              <b>Abhhyam Secure Pvt. Ltd.</b>
                            </p>
                            <p>C-7/82, Sector-8, Rohini, New Delhi-110085</p>
                          </div>
                        </li>
                        <li>
                          <div class="icon">
                            <i class="flaticon-phone-call"></i>
                          </div>
                          <div class="content">
                            <h4 class="title">Phone</h4>
                            <Link to="tel:9971713967">+91-9971713967</Link>
                          </div>
                        </li>
                        <li>
                          <div class="icon">
                            <i class="flaticon-mail"></i>
                          </div>
                          <div class="content">
                            <h4 class="title">E-mail</h4>
                            <Link to="mailto:care@abhhyamsecure.com">
                              care@abhhyamsecure.com
                            </Link>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-lg-7">
                  <div class="contact__form-wrap">
                    <h2 class="title">Give Us a Message</h2>
                    <p>
                      Your email address will not be published. Required fields
                      are marked *
                    </p>
                    <form
                      id="contact-form"
                      onSubmit={handleSubmit}
                      method="POST">
                      <div class="form-grp">
                        <textarea
                          name="message"
                          placeholder="Message"
                          onChange={(e) =>
                            setMessage(e.target.value)
                          }></textarea>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-grp">
                            <input
                              type="text"
                              name="name"
                              placeholder="Name"
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grp">
                            <input
                              type="email"
                              name="email"
                              placeholder="Email"
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-grp">
                            <input
                              type="number"
                              name="phone"
                              placeholder="Phone"
                              onChange={(e) => setPhone(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="form-grp checkbox-grp">
                        <input type="checkbox" name="checkbox" id="checkbox" />
                        <label for="checkbox">
                          Save my name, email, and website in this browser for
                          the next time I comment.
                        </label>
                      </div>
                      <button type="submit" class="btn">
                        Submit post
                      </button>
                    </form>
                    <p class="ajax-response mb-0"></p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    </Weblayout>
  );
}

export default Contact;
