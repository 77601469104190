import RequestCall from "../components/Requestcall";
import Weblayout from "../layout/Weblayout";
import { Link } from "react-router-dom";

function Initiative() {
  return (
    <Weblayout>
      <>
        <a
          href="#top_bottom"
          className="scroll__top scroll-to-target"
          data-target="html">
          <i className="fas fa-angle-up"></i>
        </a>

        <main className="fix" id="top_bottom">
          <section className="breadcrumb__area breadcrumb__bg about_banner_bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="breadcrumb__content">
                    <h2 className="title">Initiative</h2>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="/">Home</a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page">
                          Initiative
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="breadcrumb__shape">
              <img src="assets/img/images/breadcrumb_shape01.png" alt="" />
              <img
                src="assets/img/images/breadcrumb_shape02.png"
                alt=""
                className="rightToLeft"
              />
              <img src="assets/img/images/breadcrumb_shape03.png" alt="" />
              <img src="assets/img/images/breadcrumb_shape04.png" alt="" />
              <img
                src="assets/img/images/breadcrumb_shape05.png"
                alt=""
                className="alltuchtopdown"
              />
            </div>
          </section>

          <section className="services__details-area">
            <div className="container">
              <div className="services__details-wrap">
                <div className="row">
                  <div className="col-70 order-0 order-lg-2">
                    <div className="services__details-top">
                      <div className="row gutter-24 align-items-center">
                        <div className="col-49">
                          <div className="services__details-thumb services__details-thumb-two">
                            <img
                              src="assets/img/marketing_plan/initiative/1.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-51">
                          <div className="services__details-top-content">
                            <h2 className="title">PARA-1: Concept Note</h2>
                            <p>
                              Employee welfare and care is a cornerstone of
                              organizaonal success. When organizaons invest in
                              the well-being of their employees, they create a
                              workplace where employees are sasfied, movated,
                              and commied to achieving the organizaon's goals.
                              Employee welfare is not just an ethical obligaon;
                              it is a strategic advantage that posively impacts
                              an organizaon's performance, culture, and
                              reputaon.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="services__details-content">
                      <p>
                        As organizaons connue to evolve, they must recognize
                        that the heart of their success lies in nurturing the
                        well-being of their employees. By invesng in employees'
                        well-being and providing the necessary support,
                        organizaons can create a posive and producve work
                        environment, improve job sasfacon, and ulmately achieve
                        beer business results. Employee care is a win-win,
                        benefing both employees and the organizaon. If employee
                        happy, the company is happy, if employee secure, the
                        company secure or we can say If cizen is secured, the
                        country will be secured
                      </p>
                      <div className="services__details-list-two mb-2">
                        <div className="row gutter-24">
                          <div className="col-md-6">
                            <div className="services__details-list-box-two">
                              <div className="icon">
                                <img src="assets/img/marketing_plan/initiative/2.png"></img>
                              </div>
                              <div className="content">
                                <h4 className="title">PARA-2: Vision</h4>
                                <p>
                                  Fostering a workplace culture dedicated to
                                  securing the life of every employee, where
                                  holisc well-being, professional growth, and
                                  comprehensive benefits converge to create a
                                  thriving and secure future for all.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="services__details-list-box-two">
                              <div className="icon">
                                <img src="assets/img/marketing_plan/initiative/3.png"></img>
                              </div>
                              <div className="content">
                                <h4 className="title">PARA-3: Mission</h4>
                                <p>
                                  “Ensuring the well-being and safety of every
                                  employee, fostering a secure and thriving work
                                  environment."
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="services__details-inner-two">
                        <div className="row gutter-24 align-items-center">
                          <div className="col-100 order-0 order-md-0">
                            <div className="services__details-inner-img">
                              <img
                                src="assets/img/marketing_plan/initiative/4.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="col-100">
                            <div className="services__details-inner-content-two">
                              <h4 className="title mt-2 mb-2">
                                PARA-4: Employment needs of the day
                              </h4>
                              <p>
                                The employment landscape in India presents a set
                                of challenges and opportunies. While
                                unemployment rates have been a concern, the
                                Government and stakeholders have taken several
                                iniaves to address the issue. Skill development
                                programs like Skill India and PMKVY aim to
                                bridge the skill gap and enhance employability.
                                Startup and entrepreneurship support iniaves
                                foster innovaon and job creaon. Digital
                                transformaon through the Digital India campaign
                                opens up new avenues for employment. Collaboraon
                                between academia and industry helps align
                                educaon with industry needs. Infrastructure
                                development projects generate employment
                                opportunies. Rural employment programs like
                                MGNREGA provide livelihood support. Internaonal
                                collaboraons promote job placements abroad.
                                Women empowerment schemes promote women’s
                                parcipaon in the workforce. Reskilling and
                                upskilling programs address the impact of
                                technology. However, sustained efforts and
                                collaboraon among stakeholders are necessary to
                                overcome these challenges and create a robust
                                employment ecosystem in India. There is a huge
                                need to take various iniave to develop the
                                employment opportunies with secure life of
                                employees.
                              </p>
                            </div>
                          </div>
                          <div className="col-100 order-0 order-md-0 mt-2">
                            <div className="services__details-inner-img">
                              <img
                                src="assets/img/marketing_plan/initiative/5.png"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <h2 className="title-two">
                        PARA-5: HRM and placement sector india
                      </h2>
                      <div className="col-100 order-0 order-md-0 mb-2">
                        <div className="services__details-inner-img">
                          <img
                            src="assets/img/marketing_plan/initiative/6.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <p>
                        The Indian government has played a very important role
                        in shaping the placement sector. Iniaves like "Make in
                        India" and "Startup India" have spurred job creaon in
                        manufacturing and the startup ecosystem. Addionally,
                        schemes like the Naonal Career Service (NCS) and the
                        Skill India mission aim to provide job-related services,
                        career counselling, and skill development to job
                        seekers.
                      </p>
                      <p>
                        The placement sector in India has seen tremendous growth
                        and innovaon, it sll faces challenges. One of the
                        primary challenges is the need for connuous upskilling
                        and reskilling of the workforce to meet the demands of a
                        rapidly evolving job market. Addionally, ensuring
                        inclusivity and addressing unemployment in rural and
                        underserved areas remains a priority.
                      </p>
                      <p>
                        The placement sector in India is undergoing a
                        transformaon driven by technological advancements,
                        changing work models, and government iniaves. It has
                        become more dynamic, diverse, and inclusive, connecng
                        job seekers with a wide range of employment opportunies.
                        As the sector connues to evolve, it will play a crucial
                        role in shaping India's workforce and economic future.
                      </p>
                      <p>
                        In our unwavering commitment to excellence, we go beyond
                        tradional service delivery to provide the best-in-class
                        soluons that priorize both the well-being of employees
                        and the care of employers. Our comprehensive suite of
                        services is designed not only to meet professional needs
                        but to safeguard the holisc health and security of every
                        employee. We recognize that a thriving workforce is the
                        backbone of a successful organizaon, and as such, we
                        integrate innovave approaches that ensure employees
                        secure life plans, sasfacon, personal growth, and
                        work-life balance. Simultaneously, we understand the
                        vital role employers play in this equaon. Our services
                        extend to assisng employers in creang supporve,
                        nurturing work environments, promong a culture of
                        empathy, and implemenng measures that enhance the
                        overall welfare of their team. Through this holisc
                        approach, we aspire to set a new standard for service
                        excellence—one that seamlessly integrates the well-being
                        of both employees and employers, ulmately fostering a
                        harmonious and prosperous workplace
                      </p>
                      <div className="services__details-top">
                        <div className="row gutter-24 align-items-center">
                          <h2 className="title">
                            PARA-6: Initiative: citizen secure - country secure
                          </h2>
                          <div className="col-49">
                            <div className="services__details-thumb services__details-thumb-two">
                              <img
                                src="assets/img/marketing_plan/initiative/7.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="col-51">
                            <div className="services__details-top-content">
                              <p>
                                Ensuring the security of cizens requires a
                                holisc approach that addresses their basic needs
                                across mulple dimensions. Governments and
                                various organizaons must work together to
                                provide physical safety, economic security,
                                social safety nets, food security, shelter,
                                healthcare, mental health support, disaster
                                preparedness, employment opportunies, and access
                                to informaon. By meeng these fundamental needs,
                                we create a society where cizens can thrive,
                                contribute, and feel secure, ulmately leading to
                                a more stable and prosperous life of cizen as
                                well as naon.
                              </p>
                              <p>
                                The Aim of Abhhyam Secure Life (ASL) is to
                                support the Government and facilitate each and
                                every organizaon to take iniave and steps to
                                secure the employee. Together, we can extend all
                                possible resources to the employee to Life a
                                happy and secure life
                              </p>
                              <p>
                                The Human Resources management services by
                                Abhhyam Secure Life (ASL) will provide you with
                                all the possible support to achieve the goal of
                                a secure life of employees.
                              </p>
                              <p>
                                To secure the life and future of each employee
                                and cizen, Abhhyam Secure Private Limited (ASPL)
                                planned to offer various pensionary benefits on
                                compleon of minimum 96 months of tenure through
                                the employer. A secure life offer with lifeme
                                pension bond and other pensionary benefits will
                                help the employer to retain their employees to
                                serve at least 96 months of tenure.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-30">
                    <aside className="services__sidebar">
                      <div className="sidebar__widget sidebar__widget-three">
                        <div className="sidebar__cat-list-two sidebar__cat-list-three">
                          <ul className="list-wrap">
                            <li>
                              <Link to="/initiative">
                                Initiative{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/direct_marketing_plan">
                                Marketing Plan{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/advantages">
                                Advantages{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/incentives">
                                Incentives{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/members">
                                Members{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                            <li>
                              <Link to="/general">
                                General{" "}
                                <i className="flaticon-arrow-button"></i>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="sidebar__widget sidebar__widget-two">
                        <div className="sidebar__contact sidebar__contact-two sidebar_head_contact">
                          <h2 className="title">
                            If You Need Any Help Contact With Us
                          </h2>
                          <Link to="tel:0123456789" className="btn">
                            <i className="flaticon-phone-call"></i>
                            +91-9971713967
                          </Link>
                        </div>
                      </div>
                      <div className="sidebar__widget sidebar__widget-three">
                        <h4 className="sidebar__widget-title">Brochure</h4>
                        <div className="sidebar__brochure sidebar__brochure-two">
                          <p>Abhhyam Secure Life </p>
                          <a
                            href="/assets/img/marketing_plan/abhhyam_secure_life.pdf"
                            download={""}>
                            <i className="far fa-file-pdf"></i>PDF. Download
                          </a>
                        </div>
                      </div>
                    </aside>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <RequestCall />
        </main>
      </>
    </Weblayout>
  );
}

export default Initiative;
