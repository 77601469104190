import axios from "axios";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";

function RequestCall() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let body = JSON.stringify({
        name: name,
        phone: phone,
        email: email,
        type: "REQUEST",
      });
      const response = await axios.post(
        "https://abhhyamsecure.com/api/api/v1/website/submit-contact",
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      document.getElementById("request-form").reset();
      toast.success(response.data.msg);
      setName("");
      setEmail("");
      setPhone("");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <section className="call-back-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="call-back-content">
                <div className="section-title white-title mb-10 tg-heading-subheading animation-style3">
                  <h2 className="title tg-element-title">
                    Request A Call Back
                  </h2>
                </div>
                <p>
                  Ever find yourself staring at your computer screen a good
                  consulting slogan to come to mind? Oftentimes.
                </p>
                <div className="shape">
                  <img
                    src="assets/img/images/call_back_shape.png"
                    alt=""
                    data-aos="fade-right"
                    data-aos-delay="400"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="call-back-form">
                <form onSubmit={handleSubmit} method="post" id="request-form">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-grp">
                        <input
                          type="text"
                          placeholder="Name *"
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-grp">
                        <input
                          type="email"
                          placeholder="E-mail *"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-grp">
                        <input
                          type="number"
                          placeholder="Phone *"
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <button type="submit" className="btn">
                        Send Now
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default RequestCall;
